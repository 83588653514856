import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/account/login/login.component';
import { LogoutComponent } from './pages/account/logout/logout.component';
import { PlantillaComponent } from './pages/plantilla/plantilla.component';
import { ActualizaDatosComponent } from './pages/account/actualiza-datos/actualiza-datos.component';     
import { DesbloqueaUsuarioComponent } from './pages/account/desbloquea-usuario/desbloquea-usuario.component';
import { RestablecerContrasenaComponent } from './pages/account/restablecer-contrasena/restablecer-contrasena.component';
import { AuthGuard } from './services/auth/auth.guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent, },
  { path: 'template', component: PlantillaComponent },
  { path: 'seguros-atlas', loadChildren: () => import('./pages/seguros-atlas/seguros-atlas.module').then(m => m.SegurosAtlasModule) },
  { path: '', loadChildren: () => import('./pages/seguros-atlas/atlas-card/atlas-card.module').then(m => m.AtlasCardModule) },
  { path: '', loadChildren: () => import('./pages/seguros-atlas/card/card.module').then(m => m.CardModule) },
  { path: 'actualiza-datos', component: ActualizaDatosComponent },
  { path: 'actualiza-datos/:data', component: ActualizaDatosComponent },
  { path: 'desbloquea-usuario/:data', component: DesbloqueaUsuarioComponent },
  { path: 'restablecer-contraseña/:data', component: RestablecerContrasenaComponent },
  { path: 'generales', loadChildren: () => import('./pages/generales/generales.module').then(m => m.GeneralesModule) },
  { path: 'proveedores', loadChildren: () => import('./pages/proveedores/proveedores.module').then(m => m.ProveedoresModule) },
  { path: 'facturacion', loadChildren: () => import('./pages/facturacion/facturacion.module').then(m => m.FacturacionModule) },
  { path: '', loadChildren: () => import('./pages/paginas.module').then(m => m.PaginasModule) },
  { path: '**', redirectTo: '/dashboard-agente', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }