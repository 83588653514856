import { Component, OnInit, Inject, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { switchMap, catchError, filter } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { NotificacionesPushService } from 'src/app/services/auth/notificaciones-push.service';
import { MatInput } from '@angular/material/input';

import { ActualizaDatosService } from './../../../services/auth/actualiza-datos/actualiza-datos.service';
import { Router } from '@angular/router';
import { IResponseRestablecerPassword } from 'src/app/models/account/account.model';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-modal-ingresar',
  templateUrl: './modal-ingresar.component.html',
  styleUrls: ['./modal-ingresar.component.scss']
})
export class ModalIngresarComponent implements OnInit, AfterViewInit {

  @ViewChild('passwordInput') passwordInput: ElementRef<HTMLInputElement>;

  formIniciar = new FormGroup({
    Usuario: new FormControl(null, Validators.required),
    Password: new FormControl(null, Validators.required),
    RememberMe: new FormControl(false, Validators.required),
    devicetoken: new FormControl("123sddsfewr" ),
  });
  loading = false;
  procesando = false;
  msgError = '';

  constructor(
    public dialogRef: MatDialogRef<ModalIngresarComponent>,
    private notiPushService: NotificacionesPushService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService, private router: Router, private actDatos: ActualizaDatosService,
    private snackBar: MatSnackBar,) { }

  ngOnInit() {
    this.formIniciar.patchValue(this.data.login);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.passwordInput.nativeElement.focus();
    }, 5)
  }

  closeDialog() {
    this.dialogRef.close();
  }

  login() {
    this.loading = true;
    this.msgError = '';
    this.actDatos.changeValue(this.data.login.Usuario);
    this.notiPushService.generarTokenFirebase().pipe(
      switchMap(itm => {
        if (itm) this.formIniciar.get('devicetoken').setValue(itm);
        return this.authService.iniciarSesion(this.formIniciar.value).pipe(
          catchError(err => {
            this.loading = false;
            return of({ error: err.error });
          }),
          switchMap(lnActual => {
            if (lnActual && !lnActual.error) {
              return this.authService.iniciaSesionParalela(this.formIniciar.value).pipe(
                catchError(err => of({ error: err.message }))
              );
            } else {
              return of({ lnActual });
            }
          })
        );
      })
    ).subscribe(data => {
      console.log(data);
      this.loading = false;
      if (data.lnActual ? !data.lnActual.error : true) {
        this.closeDialog();
        return;
      }
  
      const err = data.lnActual.error || [];
  
      if (!err.length) {
        this.abrirSnackBar('Verifica tu contraseña.', `Autorización`, 'errorSnackbar');
        return;
      }
  
      err.forEach((element, index) => {
        if (element.Visible) this.msgError = index === 0 ? element.Exception : `${this.msgError} ${element.Exception}`;
      });
  
      if (JSON.stringify(err).includes("El usuario debe actualizar sus datos")) {
        this.router.navigate(['actualiza-datos']);
      }
  
      this.abrirSnackBar(this.msgError, `Autorización`, 'errorSnackbar');
      this.closeDialog();
    }, error => {
      console.log(error);
      this.closeDialog();
    });
  
    this.authService.actualizaCreaSesion(this.data.login.Usuario).subscribe((_) => { });
  }


  private abrirSnackBar(mensaje: string, titulo: string, clase: string) {
    const config: MatSnackBarConfig = {
      duration: 5000,
      panelClass: clase,
    };
    this.snackBar.open(mensaje, titulo, config);
  }

  restablecerPassword(){
    this.procesando = true;
    const request = { Usuario : this.formIniciar.get("Usuario").value }
    this.authService.restablecerPassword(request).subscribe((respuesta) => {
      this.procesando = false;
      this.closeDialog();
      if(respuesta.validacion)  this.abrirSnackBar(respuesta.datos, "Restablecer contraseña", "exitoSnackbar");
      if(!respuesta.validacion)  this.abrirSnackBar(respuesta.datos, "Restablecer contraseña", "avisoSnackBar");
    }, error => {
      this.procesando = false;
      this.closeDialog();
      this.abrirSnackBar(error.datos, "Restablecer contraseña", "errorSnackbar");
    });
  }
}
